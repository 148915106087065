import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import {List,ListItem,ListItemText,Typography} from '@material-ui/core';

export default function Telemedicine() {
  return (
    <Layout>
        {/* <Heading></Heading> */}
        <Typography variant="h2" component='h1'>Telemedicine</Typography>

        <Typography variant="h4" component='h2'>Preparing for a Telemedicine Appointment</Typography>

        <Typography variant="body1" component='p'>Conditions that may be evaluated through the use of Teledermatology</Typography>

        <Typography variant="body1" component='p'>  Acne, Psoriasis, Rosacea, Atopic Dermatitis, Seborrhea, Lesions of concern</Typography>
        <Typography variant="h5" component='h2'>General Requirements</Typography>
        <List >
            <ListItem >
                <ListItemText inset>The following dermatological conditions would be considered: Acne, Psoriasis, Rosacea, Atopic Dermatitis, Seborrhea, and lesions of concern</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>Device with a camera, microphone, screen, speakers/headphone</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>Internet connection</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>Room with good lighting and privacy</ListItemText>
            </ListItem>
        </List>

        <Typography variant="h5" component='h2'>New Patients</Typography>
        <List >
            <ListItem >
                <ListItemText inset>New patients must sign consents electronically</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>We must be able to receive email to receive the consent documents. The consents must be signed before you can be seen</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>New patients must show their ID and insurance cards to the office staff on the first appointment by presenting to the camera</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>We will take a screenshot of the front and back of the insurance cards</ListItemText>
            </ListItem>
        </List>

        <Typography variant="h5" component='h2'>Established Patients</Typography>
        <List >
            <ListItem >
                <ListItemText inset>We will send link to the meeting via email or text message which will grant access the video conference</ListItemText>
            </ListItem>
            <ListItem >
                <ListItemText inset>You first will be connected to an office staff member to review your chart, then you will speak to the provider</ListItemText>
            </ListItem>
        </List>

        <Typography variant="h5" component='h2'>Nursing Home or Assisted Living</Typography>
        <Typography variant="body1" component='p'>We may be partnered with your facility. If so, we will have provided them an iPad to facilitate the telehealth visit. 
            Your facility’s care taker or medical staff member will initiate the call and position the iPad.</Typography>

    </Layout>
  );
}
